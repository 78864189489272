
import Vue from 'vue';

export default Vue.extend({
  name: 'v-input',
  props: {
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    cssVariant: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    defaultValue: {
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: this.value,
      inputType: this.type,
      inputLabel: this.label,
      validationError: false,
    };
  },
  methods: {
    createClassesArray(classesPrefix: string) {
      let classesArray: string[] = [classesPrefix];

      let variant: string = this.cssVariant;
      let type: string = this.type;
      let required: string = this.required.toString();

      variant = variant ? `${classesPrefix}--${variant}` : `${classesPrefix}--default`;
      type = type ? `${classesPrefix}--${type}` : `${classesPrefix}--text`;
      required = this.required ? `${classesPrefix}--required` : '';

      classesArray = [...classesArray, variant, type, required];
      return classesArray;
    },
    handleInput(val: any) {
      this.$emit('input', val);
    },
    setValidationError(errorMessage: string) {
      this.inputLabel = errorMessage;
      this.validationError = true;
    },
    autoSave(e: any) {
      if (e.target.type === 'url' && !e.target.checkValidity()) {
        this.setValidationError('Pole musi zawierać adres URL wraz z przedrostkiem http:// lub https://');
      } else if (e.target.validity.valueMissing) {
        this.setValidationError('Wartość tego pola nie może być pusta');
      } else {
        this.inputLabel = this.label;
        this.validationError = false;
      }
      this.$emit('auto-save');
    },
    switchPasswordVisibility(e: any) {
      e.preventDefault();
      if (this.type === 'password') {
        this.inputType = this.inputType === 'password' ? 'text' : 'password';
      }
    },
  },
  computed: {
    inputClasses(): string[] {
      return this.createClassesArray('inpt');
    },
    labelClasses(): string[] {
      return this.createClassesArray('lbl');
    },
    typeIsPassword(): boolean {
      return (this.type === 'password');
    },
    attrs(): any {
      const { ...attrs } = this.$attrs;
      return attrs;
    },
  },
  watch: {
    defaultValue: function() {
      this.$nextTick(function() {
        this.content = this.defaultValue;
        this.handleInput(this.content);
      });
    },
    value: function() {
      this.$nextTick(function() {
        this.content = this.value;
        this.handleInput(this.content);
      });
    },
    content: function() {
      this.$emit('input', this.content);
    },
  },
});
